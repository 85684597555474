import { motion } from "framer-motion";
import arrow from '../../utils/images/arrow_right.svg';
import completed from '../../utils/images/completed.svg';
import useUserStore from '../../utils/stores/useUserStore';
import { calculateCurrentPeriod } from '../QuizModule/utils/UtilityFunctions';
import LoadingComponent from '../../utils/LoadingComponent';
const QuizDetails = ({ item, isYours }) => {
    const { settings } = useUserStore();
    const periodObject = calculateCurrentPeriod(item, settings);
    const isQuizFilledByStaff = item.answer_on_question_for_staff.some(item => item.answer === null);
    const isQuizFilledByInspector = item.answer_on_question_for_inspector.some(item => item.answer === null);

    // Варианты для каждого элемента
    const itemVariants = {
        hidden: { opacity: 0, },
        visible: { opacity: 1, transition: { duration: 0.2, type: 'tween' } },
    };

    return (
        <motion.div
            className='employee-module__item'
            variants={itemVariants}
        >
            <div className='employee-module__item__name-block'>
                <p className='employee-module__item__name'>{item.full_name}</p>
                <p className='employee-module__item__post'>{item.staff_position}</p>
            </div>
            {!isQuizFilledByStaff ? (
                <img className='employee-module__item__success' src={completed} alt="Completed icon" />
            ) : (
                <img className='employee-module__item__success' />
            )}
            {!isQuizFilledByInspector ? (
                <img className='employee-module__item__success' src={completed} alt="Completed icon" />
            ) : (
                <img className='employee-module__item__success' />
            )}
            {!isYours ? (
                item.status_doc_quiz_name === 'Оценен руководителем' ? (
                    <a href={`/quiz/${item.id}`} className='employee-module__item__status'>Завершено</a>
                ) : item.status_doc_quiz_name === 'Оценен сотрудником' ? (
                    <a className='employee-module__item__status' href={`/quiz/${item.id}`}>Ожидает оценки руководителя</a>
                ) : item.status_doc_quiz_name === 'Создана' ? (
                    <a className='employee-module__item__status' href={`/quiz/${item.id}`}>Ожидает оценки сотрудника</a>
                ) : null
            ) : (
                item.status_doc_quiz_name === 'Оценен руководителем' ? (
                    <a href={`/quiz/${item.id}`} className='employee-module__item__status'>Завершено</a>
                ) : (item.status_doc_quiz_name === 'Оценен сотрудником' || periodObject.currentPeriod === 'inspector') ? (
                    <a className='employee-module__item__button' href={`/quiz/${item.id}`}>Оценить</a>
                ) : item.status_doc_quiz_name === 'Создана' ? (
                    <a className='employee-module__item__status' href={`/quiz/${item.id}`}>Ожидает оценки сотрудника</a>
                ) : null
            )}
            <a className='employee-module__item__link' href={`/profile/${item.staff_id}`}>
                <p>История</p>
                <img alt='Перейти в профиль' className='employee-module__arrow' src={arrow} />
            </a>
        </motion.div>
    );
};


const QuizList = ({ quizList, isYours, isLoading }) => {

    return (
        <motion.div
            className="quiz-list"
        >
            {isLoading ? (
                <LoadingComponent />
            ) : quizList.length > 0 ? (
                quizList.map((item) => (
                    <QuizDetails key={item.id} item={item} isYours={isYours} />
                ))
            ) : (
                <div className="empty-message">Пока тут пусто</div>
            )}
        </motion.div>
    );
};

export default QuizList;
