export class Api {
    constructor(options) {
        this._mode = options.mode;
        this._baseUrl = options.baseUrl;
        this._headers = options.headers;
    }

    async fetchUser(token) {
        const response = await fetch(`${this._baseUrl}/users/me`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`,
            },
        });
        return this._handleResponse(response);
    }

    async fetchProfile(token, currentUserId) {
        const response = await fetch(`${this._baseUrl}/profile?user__id=${currentUserId}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`,
            },
        });
        return this._handleResponse(response);
    }

    async fetchProfileByProfileId(token, profileId) {
        const response = await fetch(`${this._baseUrl}/profile/${profileId}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`,
            },
        });
        return this._handleResponse(response);
    }

    async fetchScores(token, userId) {
        const response = await fetch(`${this._baseUrl}/quiz?staff_id=${userId}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`,
            },
        });
        return this._handleResponse(response);
    }

    async fetchEmployee(token, userId, page) {
        const response = await fetch(`${this._baseUrl}/profile?manager=${userId}${page ? `&page=${page}` : ''}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`,
            },
        });
        return this._handleResponse(response);
    }

    async fetchScoresByInspectorId(token, userId, status) {
        const response = await fetch(`${this._baseUrl}/quiz?inspector_id=${userId}${status ? `&status_doc_quiz__name=${status}` : ''}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`,
            },
        });
        return this._handleResponse(response);
    }

    async getUserById(token, userId) {
        const response = await fetch(`${this._baseUrl}/users/${userId}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`,
            },
        });
        return this._handleResponse(response);
    }

    async _handleResponse(res) {
        if (res.ok) {
            if (res.status === 200 && res.headers.get('content-length') === '0') {
                return null;
            }
            return res.json();
        } else {
            const errorData = await res.json();
            return Promise.reject({
                message: "Произошла ошибка",
                status: res.status,
                data: errorData
            });
        }
    }

    async getQuizById(token, quizId, params) {
        const url = new URL(`${this._baseUrl}/quiz/${quizId}`);
        url.search = new URLSearchParams(params).toString();

        const response = await fetch(url.toString(), {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`,
            },
        });
        return this._handleResponse(response);
    }

    async getQuizes(token, params) {
        const url = new URL(`${this._baseUrl}/quiz/`);
        url.search = new URLSearchParams(params).toString();

        const response = await fetch(url.toString(), {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`,
            },
        });
        return this._handleResponse(response);
    }


    async getQuestionById(token, questionId) {
        const response = await fetch(`${this._baseUrl}/questions/${questionId}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`,
            },
        });
        return this._handleResponse(response);
    }

    async patchFilledQuiz(token, quizId, quiz, role) {
        let bodyContent = {};
        bodyContent.comment = quiz.comment;
        switch (role) {
            case 'calibrate':
                bodyContent.answer_on_question_for_calibration = quiz.answer_on_question_for_calibration;
                break;
            case 'inspector':
                bodyContent.answer_on_question_for_inspector = quiz.answer_on_question_for_inspector;
                break;
            case 'yours':
                bodyContent.answer_on_question_for_staff = quiz.answer_on_question_for_staff;
                break;
            default:
                throw new Error('Invalid role');
        }
        const response = await fetch(`${this._baseUrl}/quiz/${quizId}/`, {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`,
            },
            body: JSON.stringify(bodyContent),
        });

        return this._handleResponse(response);
    }

    async patchCalibrateQuiz(token, quizId, userId) {
        const response = await fetch(`${this._baseUrl}/quiz/${quizId}/`, {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`,
            },
            body: JSON.stringify({
                "calibration_id": `${userId}`
            }),
        });

        return this._handleResponse(response);
    }

    async patchStatusQuiz(token, quizId, status) {
        const response = await fetch(`${this._baseUrl}/quiz/${quizId}/`, {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`,
            },
            body: JSON.stringify({
                "status_doc_quiz_name": `${status}`
            }),
        });

        return this._handleResponse(response);
    }

    async searchByAnyField(token, request, page) {
        const response = await fetch(`${this._baseUrl}/profile?search=${request}${page ? `&page=${page}` : ''}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`,
            },
        });
        return this._handleResponse(response);
    }

    async patchQuizInspector(token, inspectorId, quizId) {
        const response = await fetch(`${this._baseUrl}/quiz/${quizId}/`, {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`,
            },
            body: JSON.stringify({
                "inspector_id": `${inspectorId}`
            }),
        });
        return this._handleResponse(response);
    }


    async getStats(token) {
        const response = await fetch(`${this._baseUrl}/quiz/download_statistic_report/`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`,
            },
        });
        return this._handleResponse(response);
    }

    async createQuiz(token, body) {
        const response = await fetch(`${this._baseUrl}/quiz/`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`,
            },
            body: JSON.stringify(body),
        });
        return this._handleResponse(response);
    }

    async createMassQuiz(token, body) {
        const response = await fetch(`${this._baseUrl}/create_quiz/`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`,
            },
            body: JSON.stringify(body),
        });
        return this._handleResponse(response);
    }

    async getBuIdByHrId(token, id) {
        const response = await fetch(`${this._baseUrl}/hr-bu?hr_id=${id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`,
            },
        });
        return this._handleResponse(response);
    }

    async getBuHrList(token) {
        const response = await fetch(`${this._baseUrl}/hr-bu`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`,
            },
        });
        return this._handleResponse(response);
    }

    async getDepartmentsByBuId(token, id) {
        const body = id ? { 'BU': id } : {};
        const response = await fetch(`${this._baseUrl}/quiz/get_department_for_bu/`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`,
            },
            body: JSON.stringify(body),
        });
        return this._handleResponse(response);
    }

    async getManagersByBuId(token, id) {
        const body = id ? { 'BU': id } : {};
        const response = await fetch(`${this._baseUrl}/quiz/get_inspector_for_bu/`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`,
            },
            body: JSON.stringify(body),
        });
        return this._handleResponse(response);
    }

    async getQuizByBuId(token, id) {
        const response = await fetch(`${this._baseUrl}/get_quiz_for_bu/`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`,
            },
            body: JSON.stringify({
                "quarter": "2024-06-30",
                'BU': id
            }),
        });
        return this._handleResponse(response);
    }

    async calibrateMassQuiz(token, buId, quarter, userId) {
        const response = await fetch(`${this._baseUrl}/quiz/get_quiz_for_bu/`, {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`,
            },
            body: JSON.stringify({
                "quarter": quarter,
                "BU": buId,
                "calibration_id": userId
            }),
        });
        return this._handleResponse(response);
    }

    async getSettings(token) {
        const response = await fetch(`${this._baseUrl}/settings/`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`,
            },
        });
        return this._handleResponse(response);
    }

    async getFullReport(token, quarter) {
        const response = await fetch(`${this._baseUrl}/quiz/download_full_assessment_results?quarter=${quarter}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`,
            }
        });
        return this._handleResponse(response);
    }

    async patchQuizStatus(token, quizId, statusId) {
        const response = await fetch(`${this._baseUrl}/quiz/${quizId}/`, {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`,
            },
            body: JSON.stringify({
                "status_doc_quiz": statusId
            }),
        });
        return this._handleResponse(response);
    }

    async deleteQuiz(token, quizId) {
        const response = await fetch(`${this._baseUrl}/quiz/${quizId}/`, {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`,
            },
        });
        if (response.status === 204) {
            return null;
        }
        return this._handleResponse(response);
    }

    async getMailingTemplates(token) {
        const response = await fetch(`${this._baseUrl}/send_message/`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`,
            }
        });
        return this._handleResponse(response);
    }

    async sendMailing(token, recipient, text) {
        const response = await fetch(`${this._baseUrl}/send_message/`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`,
            },
            body: JSON.stringify({
                "to_users": recipient,
                "text": text
            }),
        });
        return this._handleResponse(response);
    }
}



// http://10.61.251.9:8080/
//plus.pridex.ru
const api = new Api({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    headers: {
        "Content-Type": "application/json",
    },
});

export default api;
