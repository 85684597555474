import React from 'react';
import ReactDOM from 'react-dom/client';
import { OverlayScrollbars } from 'overlayscrollbars';
import 'overlayscrollbars/styles/overlayscrollbars.css';
import './index.css';
import App from './App';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import ScrollToTop from './utils/ScrollToTop';
document.addEventListener('DOMContentLoaded', () => {
  OverlayScrollbars(document.body, {
      scrollbars: {
          autoHide: 'scroll',
      },
      overflowBehavior: {
          x: 'hidden',
      },
  });
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <ScrollToTop />
    <App />
  </BrowserRouter>
);

