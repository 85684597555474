import { useState, useEffect } from 'react';

export const answerValuesToLetters = {
    '5.0': 'A',
    '4.0': 'B',
    '3.0': 'C',
    '1.5': 'D',
    '0.0': 'E'
};


export const renderAnswer = (answer) => {
    const answerKey = parseFloat(answer).toFixed(1);
    return answerValuesToLetters[answerKey] || '?';
};



const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + (days));
    return result;
};

const formatDateToISO = (date) => {
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

export const calculateCurrentPeriod = (quiz, settings) => {
    if (!quiz || !settings) {
        return {
            currentPeriod: 'staff',
            deadlineStaffDate: null,
            deadlineInspectorDate: null,
            deadlineCalibrationDate: null,
        };
    }

    const creationDate = new Date(quiz.time_create);
    const daysForStaff = parseInt(settings.find(item => item.key === 'days_for_staff')?.value, 10);
    const daysForInspector = parseInt(settings.find(item => item.key === 'days_for_inspector')?.value, 10);
    const daysForCalibration = parseInt(settings.find(item => item.key === 'days_for_calibration')?.value, 10);

    if (isNaN(daysForStaff) || isNaN(daysForInspector) || isNaN(daysForCalibration)) {
        return {
            currentPeriod: 'staff',
            deadlineStaffDate: null,
            deadlineInspectorDate: null,
            deadlineCalibrationDate: null,
        };
    }

    const currentDate = formatDateToISO(new Date());
    const deadlineStaffDate = formatDateToISO(addDays(creationDate, daysForStaff));
    const deadlineInspectorDate = formatDateToISO(addDays(creationDate, daysForStaff + daysForInspector));
    const deadlineCalibrationDate = formatDateToISO(addDays(creationDate, daysForStaff + daysForInspector + daysForCalibration));

    let currentPeriod = 'staff';

    if (currentDate <= deadlineStaffDate) {
        currentPeriod = 'staff';
    } else if (currentDate <= deadlineInspectorDate) {
        currentPeriod = 'inspector';
    } else if (currentDate <= deadlineCalibrationDate) {
        currentPeriod = 'calibration';
    } else {
        currentPeriod = 'calibration';
    }

    return {
        currentPeriod,
        deadlineStaffDate,
        deadlineInspectorDate,
        deadlineCalibrationDate,
    };
};

export function calculateCompletion(data) {
    const filteredData = data.filter(
        item => item.category_name !== "Оценка по компетенциям" && item.category_name !== "Итоговая оценка"
    );
    const totalQuestions = filteredData.length;
    const filledAnswers = filteredData.filter(
        item => item.answer !== null && item.answer !== undefined && item.answer !== ""
    ).length;
    const completionPercentage = totalQuestions > 0 ? Math.round((filledAnswers / totalQuestions) * 100) : 0;

    return {
        totalQuestions,
        filledAnswers,
        completionPercentage
    };
}

